import React from 'react';
import { Col, Row, Button, Segmented } from 'antd';


const SegmentedChoices: React.FC<{choices: string[], defaultChoice: string, onUpdate: any}> = ({choices, defaultChoice, onUpdate}) => {
  console.log(choices, defaultChoice)
  if (defaultChoice == "")
    return (<div></div>)
  return (
    <Row>
      <Segmented
        defaultValue={defaultChoice}
        size={"small"}
        style={{display: "grid"}}
        onChange={onUpdate}
        options={choices.map(t => {
          return {
            label: (<div style={{ padding: 4 }}> {t} </div>),
            value: t
          }
        })}
      /> 
    </Row>
  )
}

export default SegmentedChoices;
