import React, { useState, useEffect } from 'react';
import { Progress } from 'antd';
import { Button } from 'antd';
import { LikeOutlined, DislikeOutlined } from '@ant-design/icons';

const CountdownProgress: React.FC<{top: string, left: string, bottom: number, right: string, rerender: string}>  = ({top, left, bottom, right, rerender}) => {
  const [percent, setPercent] = useState(0);
  const [flip, setFlip] = useState(false);
  const totalSeconds = 10;
  let interval: any;

  useEffect(() => {
    setPercent(0);
    interval = setInterval(() => {
      setPercent((oldPercent) => {
        console.log(oldPercent);
        const newPercent = oldPercent + 10;
        if (newPercent >= 100) {
          clearInterval(interval);
          // Optionally reset the progress after 10 seconds
          // setTimeout(() => {setPercent(0); setFlip(!flip);}, 50);
          return 100;
        }
        return newPercent;
      });
    }, 950);

    return () => clearInterval(interval);
  }, [flip, rerender]);

  const formatText = (percent: number) => {
    // Calculate the current second based on the percent
    const currentSecond = Math.round((percent / 100) * totalSeconds);
    return `${currentSecond}s`;
  };

  return (
         <div>
           <Progress type="circle" size={"small"} percent={percent} format={() => formatText(percent)} style={{
              position: 'absolute',
              top: top,
              left: left,
              transform: 'translate(-50%, -50%)',
              opacity: 0.7, // Semi-transparent
            }} />
      <Button
        icon={<LikeOutlined />}
        className={"enlargeOnHover"}
        style={{
          position: 'absolute',
          bottom: `${bottom}%`,
          right: right,
          backgroundColor: 'rgba(0, 128, 0, 0.5)', // Semi-transparent background
          border: 'none',
        }}
      >
        Like
      </Button>

      {/* Dislike Button */}
      <Button
        icon={<DislikeOutlined />}
        className={"enlargeOnHover"}
        style={{
          position: 'absolute',
          bottom: `${bottom-7.5}%`,
          right: right,
          backgroundColor: 'rgba(255, 0, 0, 0.5)', // Semi-transparent background
          border: 'none',
        }}
      >
        Dislike
      </Button>
          </div>);
};


export default CountdownProgress;
