import React, {useState, useEffect} from 'react';
import logo from './logo.svg';
import './App.css';
import Generate from "./components/panel";
import GenerateMobile from "./components/mobilePanel";
import {theme, ConfigProvider} from 'antd';

function App() {
  const [isDesktop, setDesktop] = useState(window.innerWidth > 1450);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 1450);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  return (
    <div className="App">
      <ConfigProvider
        theme={{
          // 1. Use dark algorithm
          algorithm: theme.darkAlgorithm,
          // 2. Combine dark algorithm and compact algorithm
          // algorithm: [theme.darkAlgorithm, theme.compactAlgorithm],
        }}
      >
        {isDesktop ? (<Generate />) : (<GenerateMobile />)} 
      </ConfigProvider>
    </div>
  );
}

export default App;
