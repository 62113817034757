import React from 'react';
import { Flex } from 'antd';
import SegmentedChoices from './sizes';

const ControlView: React.FC<{size: string, character: string, style: string, sizes: string[], characters: string[], styles: string[], updateSize: any, updateCharacter: any, updateStyle: any}> = ({size, character, style, sizes, characters, styles, updateSize, updateCharacter, updateStyle}) => {
  return (
    <div>
    <Flex gap={"small"} style={{color: "white"}} vertical>
      <h1> Size </h1>
      <Flex vertical>
        <SegmentedChoices defaultChoice={size} choices={sizes} onUpdate={updateSize} />
      </Flex>
    </Flex>
    <Flex gap={"small"} vertical>
      <h1> Character </h1>
      <Flex vertical>
        <SegmentedChoices defaultChoice={character} choices={characters} onUpdate={updateCharacter} />
      </Flex>
    </Flex>
    <Flex gap={"small"} vertical>
      <h1> Style </h1>
      <Flex vertical>
         <SegmentedChoices defaultChoice={style} choices={styles} onUpdate={updateStyle} />
      </Flex>
    </Flex>
    </div>
  )
}

export default ControlView;
